<template>
    <v-container fluid class="mt-16">
        <v-flex xs12 sm6 offset-sm3 mt-13>
          <v-card
              class="mx-auto"
              outlined
              elevation="5"
          >
            <v-card-text>
              <v-row>
                <v-col cols="6" align="center" >
                  <h2 class="title">ВХОД</h2>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12" md="6" xl="6" class="mb-16" justify="center">
                  <form class="login form" @submit.prevent="login" >
                    <v-layout column>
                      <v-flex>
                        <v-text-field
                            name="email"
                            label="Почта"
                            v-model="username"
                            type="email"
                            placeholder="Почта"
                            prepend-icon="mdi-account"
                            autofocus="autofocus"
                            maxlength="150"
                            id="id_username"
                            required></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                            name="password"
                            label="Пароль"
                            v-model="password"
                            type="password"
                            prepend-icon="mdi-lock"
                            placeholder="Пароль"
                            id="id_password">
                          required></v-text-field>
                      </v-flex>
                      <v-flex class="text-xs-center" mt-5>
                        <v-btn color="success" type="submit" elevation="0">Вход</v-btn>
                        <v-btn color="accent" class="ml-4" type="" to="/forget" elevation="0">Забыли пароль?</v-btn>
                      </v-flex>
                    </v-layout>
                  </form>
                </v-col>
                <v-col cols="6" md="6" xl="6" class="hidden-sm-and-down">
                  <v-img :src='require("@/assets/images/login.png")' contain max-height="700" />
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>
        </v-flex>
    </v-container>
</template>

<script>
export default {
  data () {
    return {
      username: '',
      password: '',
    }
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push("/home")
    }
  },
  methods: {
    login: function() {
      let username = this.username;
      let password = this.password;
      this.$store
          .dispatch("login", { username, password })
          .then(() => this.$router.push("/home"))
          .catch(()=>{
            this.$store.dispatch("showSnack", {text: "Неверный логин или пароль", color: "error"})
          });
    }
  }
}
</script>
